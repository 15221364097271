let url = "";
let client_id = "";
let client_secret = "";

if (process.env.NODE_ENV === 'development') {
    url = "http://localhost:8000";
    client_id = "2";
    client_secret = "H7hzc9MI7W7SpQv5cK1SB2C7OnvG199JBqz3rdYe";
} else {
    url = "https://api.cortesia.applaon.com.br";
    client_id = "4";
    client_secret = "IE6sPTgumz8qRKDoOFVVnkfY0sSzO4enKandP66K";
}

export const Constants = {
    url: url,
    baseUrl: url + "/api/web",
    client_id: client_id,
    client_secret: client_secret
};
